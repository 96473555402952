/* eslint-disable vue/multi-word-component-names */
import { createApp } from 'vue';
import App from './App.vue';
import './assets/main.css';
import store from "./store";


import Button from "primevue/button";
import Card from 'primevue/card';
import Chip from 'primevue/chip';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import ConfirmDialog from "primevue/confirmdialog";
import DataTable from 'primevue/datatable';
import Dialog from "primevue/dialog";
import Dropdown from 'primevue/dropdown';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputText from "primevue/inputtext";
import ProgressSpinner from "primevue/progressspinner";
import Row from 'primevue/row';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Calendar from 'primevue/calendar';
import Image from 'primevue/image';
import ProgressBar from 'primevue/progressbar';
import Chart from 'primevue/chart';
import Textarea from 'primevue/textarea';

import "primeflex/primeflex.css";
import 'primeicons/primeicons.css';
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/saga-blue/theme.css';

import jwtDecode from "jwt-decode";

import router from "./router";
import routerSale from "./router/sale";
import routerAdmin from "./router/admin";
import routerProducttion from "./router/producttion";
import routerProduction from "./router/production";
import routerPlan from "./router/plan";
import routerPurchase from "./router/purchase";
import routerTransfer from "./router/transfer";
import DataView from 'primevue/dataview';
import Tag from 'primevue/tag';
import Divider from 'primevue/divider';
import SelectButton from 'primevue/selectbutton';

const app = createApp(App);

app.use(PrimeVue);
app.use(store);
app.use(ToastService);

try {
    const token = localStorage.getItem("token");
    const jwt_decode = jwtDecode(token); 
      if (jwt_decode.role.main === "เซลล์") {
        app.use(routerSale);
      } else if (jwt_decode.role.main === "แอดมิน") {
        app.use(routerAdmin);
      } else if (jwt_decode.role.main === "โปรดักชัน" && jwt_decode.role.sub === "graphic") {
        app.use(routerProducttion);
      } else if (jwt_decode.role.main === "แพลนนิ่ง") {
        app.use(routerPlan);
      } else if (jwt_decode.role.main === "จัดซื้อ") {
        app.use(routerPurchase);
      } else if (jwt_decode.role.main === "โปรดักชัน" && jwt_decode.role.sub === "production") {
        app.use(routerProduction);
      } else if (jwt_decode.role.main === "โปรดักชัน" && jwt_decode.role.sub === "qc") {
        app.use(routerProduction);
      } else if (jwt_decode.role.main === "จัดส่ง") {
        app.use(routerTransfer);
      } else {
        app.use(router);
      }
  
  } catch (err) {
      app.use(router);
  }

app.component('Toast', Toast);
app.component('Button', Button);
app.component('InputText', InputText);
app.component('Card', Card);
app.component('Dropdown', Dropdown);
app.component('Textarea', Textarea);
app.component('InputNumber', InputNumber);
app.component('Row', Row);
app.component('ColumnGroup', ColumnGroup);
app.component('Column', Column);
app.component('DataTable', DataTable);
app.component("ConfirmDialog", ConfirmDialog);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Dialog", Dialog);
app.component("InputMask", InputMask);
app.component("Chip", Chip);
app.component("Calendar", Calendar);
app.component("DataView", DataView);
app.component("Tag", Tag);
app.component("Image", Image);
app.component("Divider", Divider);
app.component("ProgressBar", ProgressBar);
app.component("Chart", Chart);
app.component("SelectButton", SelectButton);

app.mount('#app');
