import { createRouter, createWebHistory } from "vue-router";

let routes = [];
routes = [
 

  {
    path: "/",
    name: "Purchase",
    component: () => import("../components/Purchase/PurchaseView.vue"),
  },
  {
    path: "/new",
    name: "Purchase-new",
    component: () => import("../components/Purchase/PurchaseNewView.vue"),
  },
  {
    path: "/pending",
    name: "Purchase-pending",
    component: () => import("../components/Purchase/PurchasePendingView.vue"),
  },
  {
    path: "/done",
    name: "Purchase-done",
    component: () => import("../components/Purchase/PurchaseDoneView.vue"),
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
