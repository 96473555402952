<template>
  <!-- เมนูจอคอม -->
  <div style="height: 100vh"
    class="w-80 lg:w-80 sticky top-0 overflow-y-auto bg-[#F5CCA0] border-[#E48F45] border-r-2 shadow-lg rounded-br-3xl rounded-tr-3xl flex-col justify-between hidden lg:flex">
    <div class="">
      <div class="h-16 w-full flex items-center"></div>
      <h1 class="text-2xl text-center font-bold mb-10 cursor-pointer text-[#994D1C] duration-150">
        Diamond
      </h1>

      <div class="hidden border-b border-dashed lg:block border-[#EFD99B]"></div>

      <div class="flex items-center justify-between px-4 py-3 bg-[#864000]">
        <div class="flex items-center mr-5">
          <div class="mr-5">
            <div class="inline-block relative shrink-0 cursor-pointer rounded-[.95rem]">
              <svg class="shrink-0 text-[#FAF5E4] inline-block" width="32" height="32" viewBox="0 0 496 512"
                xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor"
                  d="M248 104c-53 0-96 43-96 96s43 96 96 96s96-43 96-96s-43-96-96-96m0 144c-26.5 0-48-21.5-48-48s21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48m0-240C111 8 0 119 0 256s111 248 248 248s248-111 248-248S385 8 248 8m0 448c-49.7 0-95.1-18.3-130.1-48.4c14.9-23 40.4-38.6 69.6-39.5c20.8 6.4 40.6 9.6 60.5 9.6s39.7-3.1 60.5-9.6c29.2 1 54.7 16.5 69.6 39.5c-35 30.1-80.4 48.4-130.1 48.4m162.7-84.1c-24.4-31.4-62.1-51.9-105.1-51.9c-10.2 0-26 9.6-57.6 9.6c-31.5 0-47.4-9.6-57.6-9.6c-42.9 0-80.6 20.5-105.1 51.9C61.9 339.2 48 299.2 48 256c0-110.3 89.7-200 200-200s200 89.7 200 200c0 43.2-13.9 83.2-37.3 115.9" />
              </svg>
            </div>
          </div>
          <div class="mr-2">
            <div class="hover:text-[#EFD99B] text-[#FAF5E4] transition-colors duration-200 
            ease-in-out text-base		 font-medium text-secondary-inverse">
              {{ $store.getters.firstName }} {{ $store.getters.lastName }}
            </div>

            <span class=" text-[#FAF5E4] font-medium block text-[0.85rem]">{{ $store.getters.role }}
            </span>
          </div>
        </div>
        <button class="inline-flex relative items-center group justify-end text-base font-medium leading-normal text-center 
          align-middle cursor-pointer rounded-[.95rem] transition-colors duration-150 ease-in-out text-dark bg-transparent 
          shadow-none border-0" title="ตั้งค่าโปรไฟล์">

          <span
            class="leading-none transition-colors duration-200 ease-in-out peer group-hover:text-primary text-secondary-dark">
            <svg class="text-[#FAF5E4]" width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z">
              </path>
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
            </svg>
          </span>
        </button>
      </div>

      <div class="hidden border-b border-dashed lg:block border-[#EFD99B]"></div>
      <div class="pt-5">
        <ul class="px-3 flex flex-col gap-y-3">
          <li>
            <router-link to="/">
              <li class="hover:bg-white py-2 px-2"
                :class="$route.path === '/' ? 'bg-white' : ''"
              >
              <i class="pi pi-file pr-2"></i>
              งานทั้งหมด
              </li>
            </router-link>
          </li>
            <router-link to="/new">
              <li class="hover:bg-white py-2 px-2"
                :class="$route.path === '/new' ? 'bg-white' : ''"
              >
              <i class="pi pi-file pr-2"></i>
              งานใหม่ <span v-show="newOrders.length > 0" class="ml-3 bg-orange-800 px-2 text-white rounded-full">{{ newOrders.length > 0 ? newOrders.length : null }}</span>
              </li>
            </router-link>
          <li>
            <router-link to="/pending">
              <li class="hover:bg-white py-2 px-2"
                :class="$route.path === '/pending' ? 'bg-white' : ''"
              >
              <i class="pi pi-file pr-2"></i>
              งานที่กำลังดำเนินการ <span v-show="pendingOrders.length > 0" class="ml-3 bg-orange-800 px-2 text-white rounded-full">{{ pendingOrders.length > 0 ? pendingOrders.length : null }}</span>
              </li>
            </router-link>
          </li>
          <li>
            <router-link to="/done">
              <li class="hover:bg-white py-2 px-2"
                :class="$route.path === '/done' ? 'bg-white' : ''"
              >
              <i class="pi pi-file pr-2"></i>
              งานที่เสร็จแล้ว
              </li>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="w-full flex justify-center pb-5">
      <Button label="ออกจากระบบ" class="px-2 py-1 rounded text-red-500" @click="logout" />
    </div>
  </div>

  <nav v-if="isMobile" aria-label="toggle sidebar" class="z-20 flex-col sticky top-0 items-center flex-shrink-0 w-16 py-4
     bg-white border-r-2 border-indigo-100 shadow-md sm:flex 
     rounded-tr-3xl rounded-br-3xl" style="height: 100vh">
    <!-- Logo -->
    <div class="flex py-4 items-center justify-center">
      <svg class="w-8 h-auto text-[#994D1C]" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor"
          d="M1018.72 295.472L878.848 28.03C870.688 12.43 849.584-.322 832-.322H510.064c-.351-.015-.703-.11-1.054-.127c-.288 0-.56.113-.849.128h-316.16c-17.6 0-38.752 12.72-47.024 28.256L5.473 290.223c-8.289 15.536-6.624 39.937 3.631 54.257l480.016 669.152c5.153 7.184 12 10.815 18.832 10.815c6.785 0 13.584-3.536 18.768-10.591L1014.624 349.6c10.384-14.193 12.256-38.544 4.096-54.128m-76.353-7.843H770.911l68.656-196.608zM575.343 63.677h205.968l-63.888 182.928zm92.895 223.952H370.591L511.263 85.533zm-354.351-30.544L249.71 63.677h198.816zm366.863 94.544L508.718 844.173L345.262 351.629zM436.926 831.085L92.99 351.629h184.832zm311.616-479.456H933.71l-352.976 480.56zM188.478 82.413l68.096 205.216H79.326z" />
      </svg>
    </div>
    <div class="flex flex-col items-center flex-1 p-2 space-y-4">
      <!-- Menu button -->
      <button v-if="isMobile" @click="toggleMenu"
        class="p-2 transition-colors rounded-lg shadow-md focus:outline-none focus:ring focus:ring-[#864000] focus:ring-offset-white focus:ring-offset-2"
        :class="{
          'text-white bg-[#864000]': isSidebarOpen,
          'text-[#994D1C] bg-white': !isSidebarOpen,
        }">
        <span class="sr-only">Toggle sidebar</span>
        <svg width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill="currentColor"
            d="M2 2h20v20H2zm2 2v5.5h16V4zm16 7.5H4V20h16zM5.996 6H8v2h-.004v.004h-2zM10 6h8v2h-8z" />
        </svg>
      </button>
    </div>
  </nav>

  <!-- mobile -->
  <transition name="fade">
    <div v-if="isMobile" :class="{ hidden: !isSidebarOpen }" style="height: 100vh" class="w-64 fixed inset-y-0 z-10 bg-[#F5CCA0] border-[#E48F45] flex-shrink-0 border-r-2 shadow-lg left-14 flex-col rounded-br-3xl 
      rounded-tr-3xl">
      <!-- ปุ่มปิดเมนู -->
      <button v-if="isSidebarOpen && isMobile" aria-label="Close sidebar"
        class="h-10 w-10 z-0 bg-[#F5CCA0] absolute right-0 mt-36 -mr-10 items-center rounded-tr rounded-br justify-center cursor-pointer text-[#994D1C]"
        @click="closeMenu">
        <svg class="ml-44 justify-end flex" width="24" height="24" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path fill="currentColor" fill-rule="evenodd"
            d="M4.28 3.22a.75.75 0 0 0-1.06 1.06L6.94 8l-3.72 3.72a.75.75 0 1 0 1.06 1.06L8 9.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L9.06 8l3.72-3.72a.75.75 0 0 0-1.06-1.06L8 6.94z"
            clip-rule="evenodd" />
        </svg>
      </button>

      <div class="px-2">
        <div class="flex items-center justify-center flex-shrink-0 py-10">
          <a href="#">
            <!-- <svg
                    class="text-indigo-600"
                    width="96"
                    height="53"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.691 34.703L13.95 28.2 32.09 52h8.087L18.449 23.418 38.594.813h-8.157L7.692 26.125V.812H.941V52h6.75V34.703zm27.61-7.793h17.156v-5.308H35.301v5.308zM89.19 13v22.512c0 3.703-1.02 6.574-3.058 8.613-2.016 2.04-4.934 3.059-8.754 3.059-3.773 0-6.68-1.02-8.719-3.059-2.039-2.063-3.058-4.945-3.058-8.648V.813h-6.68v34.874c.047 5.297 1.734 9.458 5.062 12.481 3.328 3.023 7.793 4.535 13.395 4.535l1.793-.07c5.156-.375 9.234-2.098 12.234-5.168 3.024-3.07 4.547-7.02 4.57-11.848V13h-6.785zM89 8h7V1h-7v7z"
                    />
                  </svg> -->

            <svg class="w-24 h-auto text-[#994D1C]" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
              <path fill="currentColor"
                d="M1018.72 295.472L878.848 28.03C870.688 12.43 849.584-.322 832-.322H510.064c-.351-.015-.703-.11-1.054-.127c-.288 0-.56.113-.849.128h-316.16c-17.6 0-38.752 12.72-47.024 28.256L5.473 290.223c-8.289 15.536-6.624 39.937 3.631 54.257l480.016 669.152c5.153 7.184 12 10.815 18.832 10.815c6.785 0 13.584-3.536 18.768-10.591L1014.624 349.6c10.384-14.193 12.256-38.544 4.096-54.128m-76.353-7.843H770.911l68.656-196.608zM575.343 63.677h205.968l-63.888 182.928zm92.895 223.952H370.591L511.263 85.533zm-354.351-30.544L249.71 63.677h198.816zm366.863 94.544L508.718 844.173L345.262 351.629zM436.926 831.085L92.99 351.629h184.832zm311.616-479.456H933.71l-352.976 480.56zM188.478 82.413l68.096 205.216H79.326z" />
            </svg>
            <!-- <img
                class="w-24 h-auto"
                src="https://raw.githubusercontent.com/kamona-ui/dashboard-alpine/main/public/assets/images/logo.png"
              /> -->
          </a>
        </div>

        <div v-for="menuItem in menuItems" :key="menuItem.text">
          <button @click="handleMenuItemClick(menuItem)"
            class="flex items-center w-full space-x-2 mt-2 group hover:bg-[#994D1C] hover:text-[#F5CCA0] rounded-lg z-50">
            <span aria-hidden="true" class="p-2 z-10 group-hover:bg-[#6B240C] group-hover:text-[#F5CCA0] rounded-lg">
              <!-- svg -->
              <Icon :iconName="menuItem.icon" class="pt-1" />
            </span>
            <span class="font-medium">{{ menuItem.text }}</span>
            <svg v-if="menuItem.submenu" width="20" height="20" fill="currentColor" viewBox="0 0 20 20" :class="{
              'rotate-180': menuItem.showSubmenu,
              'rotate-0': !menuItem.showSubmenu,
            }" class="inline mt-1 ml-1 transition-transform duration-200 transform md:-mt-1">
              <path fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
          </button>

          <!-- Additional submenu for specific items -->
          <div v-if="menuItem.submenu && menuItem.showSubmenu" class="mt-2 origin-top-right rounded-md shadow-lg">
            <div class="px-2 py-2 bg-white rounded-md shadow">
              <button v-for="subItem in menuItem.submenu" :key="subItem.text" @click="handleSubmenuItemCick(subItem)"
                class="flex items-center w-full px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
                {{ subItem.text }}
              </button>
            </div>
          </div>
        </div>
        <button @click="logout" class="w-full mt-10 bg-[#994D1C] rounded-full py-1.5 text-white">
          ออกจากระบบ
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Icon from "../Icon.vue";

export default {
  components: {
    Icon,
  },

  data() {
    return {
      isSidebarOpen: false,
      isMobile: false,
      isSubmenuOpen: false,
      menuItems: [
        {
          text: "งานทั้งหมด",
          action: "/",
          icon: "Form",
        },
        {
          text: "งานใหม่",
          action: "/new",
          icon: "Form",
          badge: 2
        },
        {
          text: "งานที่กำลังดำเนินการ",
          action: "/pending",
          icon: "Form",
        },
        {
          text: "งานที่เสร็จแล้ว",
          action: "/done",
          icon: "Form",
        },
      ],
      newOrders: [],
      pendingOrders: []
    };
  },

  mounted() {
    setInterval(() => {
      this.menuItems.forEach((menuItem) => {
        this.fetchData(menuItem);
      });
    }, 200000);
    /* this.menuItems.forEach((menuItem) => {
      this.fetchData(menuItem);
    }); */

    // ตรวจสอบหน้าจอ mobile หรือไม่
    this.isMobile = window.innerWidth <= 912;

    // เพิ่ม event listener เพื่อตรวจสอบการปรับขนาดหน้าจอ
    window.addEventListener("resize", this.handleResize);
  },
  async created() { },
  methods: {
    async fetchData() {
        try {
            const response = await axios.get(
                `${process.env.VUE_APP_DIAMOND}/schedule/purchases`,
                {
                    headers: {
                        token: localStorage.getItem("token"),
                    },
                }
            );
            this.newOrders = response.data.schedule.filter(order=>order.schedule.status[order.schedule.status.length-1].name==='new');
            this.pendingOrders = response.data.schedule.filter(order=>order.schedule.status[order.schedule.status.length-1].name!=='new'&&order.schedule.status[order.schedule.status.length-1].name!=='done');
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    },

    async logout() {
      const result = await Swal.fire({
        text: "คุณต้องการที่จะออกจากระบบหรือไม่?",
        icon: "warning",
        iconColor: "#864000",
        showCancelButton: true,
        confirmButtonColor: "#864000",
        cancelButtonColor: "#9BABB8",
        confirmButtonText: "ยืนยันออกจากระบบ",
        cancelButtonText: "ยกเลิก",
      });

      if (result.isConfirmed) {
        // ทำการลบ Token ออกจาก localStorage
        localStorage.clear();
        this.$store.commit("setLoginDefault");

        // ทำการล็อกเอาท์ใน Store
        this.$router.push("/");
      }
    },
    handleMenuItemClick(menuItem) {
      if (menuItem.action) {
        this.$router.push(menuItem.action);
      } else if (menuItem.submenu) {
        this.menuItems.forEach((item) => {
          if (item !== menuItem) {
            item.showSubmenu = false;
          }
        });
        menuItem.showSubmenu = !menuItem.showSubmenu;
      }
    },

    handleSubmenuItemCick(subItem) {
      if (subItem.path) {
        this.$router.push(subItem.path);
      }
    },

    handleResize() {
      this.isMobile = window.innerWidth <= 912;
    },
    toggleMenu() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    toggleSubmenu(menuItem) {
      menuItem.showSubmenu = !menuItem.showSubmenu;
    },
    closeMenu() {
      this.isSidebarOpen = false;
    },
  },
};
</script>

<style scoped>
/* styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
