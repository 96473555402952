import { createStore } from "vuex";
export default createStore({
  state: {
    isLoading: false,
    logedIn: false,
    token: localStorage.getItem("token"),
    user: {
      code: "",
      role: {},
      name: {
        first: "",
        last: "",
      },
    },
    id: "",
    phoneNumber: "",
    },
  getters: {
    isLoading: (state) => state.isLoading,
    logedIn: (state) => state.logedIn,
    token: (state) => state.token,
    role: (state) => state.user.role.main,
    subRole: (state) => state.user.role.sub,
    code: (state) => state.user.code,
    firstName: (state) => state.user.name.first,
    lastName: (state) => state.user.name.last,
    id: (state) => state.id,
    phoneNumber: (state) => state.phoneNumber
    },
  mutations: {
    setLoading(state, item) {
      state.isLoading = item;
    },
    setLogin(state, item) {
      state.logedIn = item.logedIn;
      state.user.code = item.user.code;
      state.user.role = item.user.role;
      state.id = item.id;
      state.phoneNumber = item.phoneNumber;
      state.user.name.first = item.firstName;
      state.user.name.last = item.lastName;
    },
    setToken(state, item) {
      state.token = item;
    },
    setLoginDefault(state) {
      state.logedIn = false;
      state.token = "";
      state.user.code = "";
      state.user.role.main = "";
 
      state.state = false;
      localStorage.clear();
    },
    
  },
  actions: {},
  modules: {},
});
