<!-- HomeIcon.vue -->
<template>
  <svg
    :class="{ 'icon-image': isHover }"
    fill="currentColor"
    width="22"
    height="22"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M22 3H2v6h1v11a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V9h1zM4 5h16v2H4zm15 15H5V9h14zM9 11h6a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isHover: Boolean,
  },
};
</script>
