<template>
  <div id="app">
    <div v-if="$store.getters.logedIn === true" class="w-full h-screen">
      <div class="flex flex-no-wrap">
        <Menu v-if="$store.getters.role === 'แอดมิน'" />
        <MenuSale v-if="$store.getters.role === 'เซลล์'" />
        <MenuPro v-if="$store.getters.role === 'โปรดักชัน' && $store.getters.subRole === 'graphic'" />
        <MenuProd v-if="$store.getters.role === 'โปรดักชัน' && $store.getters.subRole === 'production' || $store.getters.subRole === 'qc'" />
        <MenuPlan v-if="$store.getters.role === 'แพลนนิ่ง'" />
        <MenuPurchase v-if="$store.getters.role === 'จัดซื้อ'" />
        <div class="container mx-auto py-2 px-2 overflow-x-auto">
          <div class=" rounded border-dashed border-2 border-gray-300">
            <router-view />
          </div>
        </div>
      </div>
    </div>

    <div v-else>

      <Login  />

    </div>
  </div>
</template>

<script>
import jwtDecode from "jwt-decode";
import Login from "./components/Login.vue";
import Menu from "./components/Menu.vue";
import MenuSale from "./components/Sale/MenuSale.vue";
import MenuPro from "./components/Pro/MenuPro.vue";
import MenuProd from "./components/Production/MenuProduction.vue";
import MenuPlan from "./components/Plan/MenuPlan.vue";
import MenuPurchase from "./components/Purchase/MenuPurchase.vue";

export default {
  name: "App",
  components: {
    Login,
    Menu,
    MenuSale,
    MenuPro,
    MenuPlan,
    MenuPurchase,
    MenuProd,
  },
  async beforeCreate() {

    this.$store.commit("setLoading", true);

    if (localStorage.getItem("token") !== null) {

      const decode = jwtDecode(localStorage.getItem("token"));
      console.log('Decoded Token:', decode);

      if (decode && decode.role) {
        console.log('Token Role:', decode.role);
        console.log('Token Role main:', decode.role.main);
        console.log('Token:', localStorage.getItem("token"));

        localStorage.setItem("srole", decode.role.main);

        const data_login = {
          logedIn: true,
          user: {
            code: decode.code,
            role: decode.role,
          },
          firstName: decode.name.first,
          lastName: decode.name.last,
          id: decode.id,
          phoneNumber: decode.phone_number
        };

        this.$store.commit("setLogin", data_login);
        this.$store.commit("setLoading", false);

      } else {
        localStorage.clear();
        this.$router.push("/login");
      }
    } else {

      localStorage.clear();
      this.$router.push("/login");
    }
  },


};
</script>

<style>
#app {
  font-family: Kanit, sans-serif;
}
</style>

<style scoped>
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
</style>
