import { createRouter, createWebHistory } from "vue-router";

let routes = [];
routes = [
 
  // กราฟฟิก

  {
    path: "/",
    name: "ProOrder",
    component: () => import("../components/Pro/ProOrder/listPreOrder.vue"),
  },
  {
    path: "/Pro/productions",
    name: "productions",
    component: () => import("../components/Pro/productions.vue"),
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import("../components/Plan/Orders.vue"),
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
