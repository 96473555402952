<!-- HomeIcon.vue -->
<template>
  <svg
    :class="{ 'icon-image': isHover }"
    fill="currentColor"
    width="22"
    height="22"
    viewBox="0 0 15 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M12.5 16h-10c-.83 0-1.5-.67-1.5-1.5v-13C1 .67 1.67 0 2.5 0h7.09c.4 0 .78.16 1.06.44l2.91 2.91c.28.28.44.66.44 1.06V14.5c0 .83-.67 1.5-1.5 1.5M2.5 1c-.28 0-.5.22-.5.5v13c0 .28.22.5.5.5h10c.28 0 .5-.22.5-.5V4.41a.47.47 0 0 0-.15-.35L9.94 1.15A.51.51 0 0 0 9.59 1z"
    />
    <path
      fill="currentColor"
      d="M13.38 5h-2.91C9.66 5 9 4.34 9 3.53V.62c0-.28.22-.5.5-.5s.5.22.5.5v2.91c0 .26.21.47.47.47h2.91c.28 0 .5.22.5.5s-.22.5-.5.5M10 13H5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h5c.28 0 .5.22.5.5s-.22.5-.5.5m0-3H5c-.28 0-.5-.22-.5-.5S4.72 9 5 9h5c.28 0 .5.22.5.5s-.22.5-.5.5M7 7H5c-.28 0-.5-.22-.5-.5S4.72 6 5 6h2c.28 0 .5.22.5.5S7.28 7 7 7"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isHover: Boolean,
  },
};
</script>
