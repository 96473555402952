import { createRouter, createWebHistory } from "vue-router";

let routes = [];
routes = [
  {
    path: "/",
    name: "Orders",
    component: () => import("../components/Plan/Orders.vue"),
  },
  {
    path: "/Work/WorkSchedule",
    name: "WorkSchedules",
    component: () => import("../components/Plan/Work/WorkSchedules.vue"),
  },
  {
    path: "/Work/purchase",
    name: "work-purchase",
    component: () => import("../components/Purchase/PurchaseView.vue"),
  },
  {
    path: "/Work/production",
    name: "work-production",
    component: () => import("../components/Production/ProductionView.vue"),
  },
  {
    path: "/Work/transfer",
    name: "work-transfer",
    component: () => import("../components/Transfer/TransferView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
