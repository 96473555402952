<!-- HomeIcon.vue -->
<template>

  <svg
    width="22"
    height="22"
    :class="{ 'icon-image': isHover }"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M1024 800q0 93-65.5 158.5T800 1024H436l-81-1l-87-2.5l-80-4.5l-75.5-8.5l-57.5-12L13.5 979L0 957q2-88 110-155.5T384 712v-33q-52-23-90-65t-60-98.5t-32-121T192 256q0-65 25-114.5t69-80t101-46T512 0t125 15.5t101 46t69 80T832 256q0 214-71 324q23-4 39-4q93 0 158.5 65.5T1024 800M768 273q0-103-71.5-156T512 64t-184.5 53T256 273q0 68 10 125.5T298 505t60 82t90 46v138q-60 6-117.5 21T232 825.5t-72.5 38T112 898t-16 22q0 14 38 23t114 12t126.5 4t137.5 1q85 0 131-1q-67-66-67-159q0-6 3-28q-1-1-2-1h-1V633q192-41 192-360m0 399v96h-96q-13 0-22.5 9.5T640 800t9.5 22.5T672 832h96v96q0 13 9.5 22.5T800 960t22.5-9.5T832 928v-96h96q13 0 22.5-9.5T960 800t-9.5-22.5T928 768h-96v-96q0-13-9.5-22.5T800 640t-22.5 9.5T768 672"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isHover: Boolean,
  },
};
</script>
