import { createRouter, createWebHistory } from "vue-router";

let routes = [];
routes = [

//เซล
  {
    path: "/",
    name: "add-form",
    component: () => import("../components/Sale/AddForm.vue"),
  },
  {
    path: "/sale/preOrder",
    name: "sale-PreOrder",
    component: () => import("../components/Sale/PreOrder/PreOrderView.vue"),
  },
  {
    path: "/pre-productions",
    name: "pre-productions",
    component: () => import("../components/Sale/Productions/ProductionsView.vue"),
  },
  {
    path: "/calculate/:id",
    name: "Calculate",
    component: () => import("../components/Sale/Productions/Calculate.vue"),
  },
  {
    path: "/Quotation",
    name: "Quotation",
    component: () => import("../components/Sale/Quotation/quotationView.vue"),
  },
  {
    path: "/Quotation/Print/:id", 
    name: "Print-Quotation",
    component: () => import("../components/Sale/Quotation/Print_Quotation.vue"),
    props: true, 
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import("../components/Plan/Orders.vue"),
  },
  {
    path: "/Work/WorkSchedule",
    name: "WorkSchedules",
    component: () => import("../components/Plan/Work/WorkSchedules.vue"),
  },
  {
    path: "/Customer",
    name: "Customer",
    component: () => import("../components/Admin/Customer/CustomerView.vue"), 
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
