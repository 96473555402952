<template>
  <div v-if="loading" class="overlay">
    <div class="loader"></div>
  </div>
  <div class="bg-gradient-to-tr from-[#E5BA73] to-[#C58940]">
    <section
      class="p-4 flex flex-col justify-center min-h-screen max-w-md mx-auto"
    >
      <div class="p-6 bg-[#FAEAB1] rounded">
        <div
          class="flex items-center justify-center text-4xl font-black text-[#61481C] m-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="mr-3 w-10 h-10"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M5.5 16a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 16h-8z"
            />
          </svg>
          <h1 class="tracking-wide">Diamond Printing</h1>
        </div>
        <div class="flex flex-col justify-center">
          <label class="text-sm font-medium text-[#61481C]">username</label>
          <input
            v-model="username"
            @keyup.enter="login"
            class="mb-3 px-2 py-1.5 mt-1 block w-full border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#61481C] focus:ring-1 focus:ring-[#61481C]"
            type="text"
            placeholder="กรอก username"
            required
          />
          <label class="text-sm font-medium text-[#61481C]">password</label>
          <div class="relative">
            <input
              v-model="password"
              @keyup.enter="login"
              class="mb-3 mt-1 block w-full px-2 py-1.5 border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#61481C] focus:ring-1 focus:ring-[#61481C]"
              :type="seePassword ? 'text' : 'password'"
              placeholder="********"
              required
            />
            <i @click="seePassword = !seePassword" class="absolute top-1/2 right-2 -translate-y-3" :class="seePassword ? 'pi pi-eye' : 'pi pi-eye-slash'"></i>
          </div>
          
          <button
            @click="login"
            class="px-4 py-1.5 rounded-md shadow-lg bg-[#61481C] font-medium text-gray-100 block hover:bg-[#A47E3B] transition duration-300"
          >
            <span>Login</span>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import "../css/Loading.css";
import liff from '@line/liff';

export default {
  name: "Login",
  data() {
    return {
      seePassword: false,
      loading: false,
      username: "",
      password: "",
    };
  },
  created(){
    //console.log(process.env)
    //this.liffLogin()
  },
  methods: {

  async liffLogin () {
    //console.log('liff')
    liff.init({
      liffId: '2004968353-7X6abban', // Use own liffId
      withLoginOnExternalBrowser: true,
    }).then(() => {
      window.location.replace('https://liff.line.me/2004968353-7X6abban/path')
      const context = liff.getContext();
      //console.log(context);
    }).catch((err) => {
        //console.log(err);
    });
  },

  async login() {
  try {
    if (!this.username || !this.password) {
      Swal.fire({
        icon: "error",
        text: "กรุณากรอกข้อมูลให้ครบ",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    this.loading = true;
    const response = await axios.post(
      `${process.env.VUE_APP_DIAMOND}/auth/login`,
      {
        username: this.username,
        password: this.password,
      }
    );

    if (response.data.success) {
      localStorage.setItem("token", response.data.token);
      // แสดง swal ที่แจ้งเตือน
      Swal.fire({
        icon: "success",
        text: "เข้าสู่ระบบสำเร็จ",
        showConfirmButton: false,
        timer: 1500,
      });
      window.location.assign("/");
    } else {
      // แสดง swal ที่แจ้งเตือนถ้าล็อกอินไม่สำเร็จ
      Swal.fire({
        icon: "error",
        text: response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  } catch (error) {
    console.error("Login failed:");
  } finally {
    this.loading = false;
  }
},

  },
};
</script>

<style scoped>
/* styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

</style>

