<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <!-- เมนูจอคอม -->
  <div style="height: 100vh"
    class="w-80 lg:w-80 sticky top-0 overflow-y-auto bg-[#F5CCA0] border-[#E48F45] border-r-2 shadow-lg rounded-br-3xl rounded-tr-3xl flex-col justify-between hidden lg:flex">
    <div class="">
      <div class="h-16 w-full flex items-center"></div>
      <h1 class="text-2xl text-center font-bold mb-10 cursor-pointer text-[#994D1C] duration-150">
        Diamond
      </h1>

      <div class="hidden border-b border-dashed lg:block border-[#EFD99B]"></div>

      <div class="flex items-center justify-between px-4 py-3 bg-[#864000]">
        <div class="flex items-center mr-5">
          <div class="mr-5">
            <div class="inline-block relative shrink-0 cursor-pointer rounded-[.95rem]">
              <svg class="shrink-0 text-[#FAF5E4] inline-block" width="32" height="32" viewBox="0 0 496 512"
                xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor"
                  d="M248 104c-53 0-96 43-96 96s43 96 96 96s96-43 96-96s-43-96-96-96m0 144c-26.5 0-48-21.5-48-48s21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48m0-240C111 8 0 119 0 256s111 248 248 248s248-111 248-248S385 8 248 8m0 448c-49.7 0-95.1-18.3-130.1-48.4c14.9-23 40.4-38.6 69.6-39.5c20.8 6.4 40.6 9.6 60.5 9.6s39.7-3.1 60.5-9.6c29.2 1 54.7 16.5 69.6 39.5c-35 30.1-80.4 48.4-130.1 48.4m162.7-84.1c-24.4-31.4-62.1-51.9-105.1-51.9c-10.2 0-26 9.6-57.6 9.6c-31.5 0-47.4-9.6-57.6-9.6c-42.9 0-80.6 20.5-105.1 51.9C61.9 339.2 48 299.2 48 256c0-110.3 89.7-200 200-200s200 89.7 200 200c0 43.2-13.9 83.2-37.3 115.9" />
              </svg>
            </div>
          </div>
          <div class="mr-2">
            <div class="hover:text-[#EFD99B] text-[#FAF5E4] transition-colors duration-200 
            ease-in-out text-base		 font-medium text-secondary-inverse">
              {{ $store.getters.firstName }} {{ $store.getters.lastName }}
            </div>

            <span class=" text-[#FAF5E4] font-medium block text-[0.85rem]">{{ $store.getters.role }}
            </span>
          </div>
        </div>
        <button class="inline-flex relative items-center group justify-end text-base font-medium leading-normal text-center 
          align-middle cursor-pointer rounded-[.95rem] transition-colors duration-150 ease-in-out text-dark bg-transparent 
          shadow-none border-0" title="ตั้งค่าโปรไฟล์">

          <span
            class="leading-none transition-colors duration-200 ease-in-out peer group-hover:text-primary text-secondary-dark">
            <svg class="text-[#FAF5E4]" width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z">
              </path>
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
            </svg>
          </span>
        </button>
      </div>

      <div class="hidden border-b border-dashed lg:block border-[#EFD99B]"></div>

      <div class="flex-1 mt-2 px-4 space-y-3 overflow-hidden hover:overflow-auto">
        <div v-for="menuItem in menuItems" :key="menuItem.text">
          <button @click="handleMenuItemClick(menuItem)" :class="{
            'bg-[#994D1C] text-[#F5CCA0]': menuItem.showSubmenu,
            'hover:bg-[#994D1C] hover:text-[#F5CCA0]': !menuItem.showSubmenu,
          }"
            class="flex items-center w-full space-x-2 group hover:bg-[#994D1C] hover:text-[#F5CCA0] text-[#6B240C] rounded-lg">
            <span aria-hidden="true" :style="{ background: menuItem.showSubmenu ? '#6B240C' : '' }"
              class="p-2 group-hover:bg-[#6B240C] items-center group-hover:text-[#F5CCA0] rounded-lg">
              <!-- svg -->
              <Icon class="pt-1" :iconName="menuItem.icon" />
            </span>
            <span class="font-medium">{{ menuItem.text }}</span>

            <span v-if="shouldShowItemCount(menuItem) && menuItem.itemCount > 0"
              class="ml-auto text-sm bg-blue-100 rounded-full px-2 py-px text-blue-500">
              {{ menuItem.itemCount }}
            </span>


            <span class="ml-10">
              <svg v-if="menuItem.submenu" width="24" height="24" fill="currentColor" viewBox="0 0 20 20" :class="{
                'rotate-180': menuItem.showSubmenu,
                'rotate-0': !menuItem.showSubmenu,
              }" class="inline mt-1 ml-1 transition-transform duration-200 transform md:-mt-1">
                <path fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"></path>
              </svg>
            </span>
          </button>

          <!-- Additional submenu for specific items -->
          <div v-if="menuItem.submenu && menuItem.showSubmenu" class="mt-2 origin-top-right rounded-md shadow-lg">
            <div class="px-2 py-2 bg-white rounded-md shadow">
              <button v-for="subItem in menuItem.submenu" :key="subItem.text" @click="handleSubmenuItemCick(subItem)"
                class="flex items-center w-full px-4 py-2 mt-2 text-sm font-medium bg-transparent rounded-lg md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
                {{ subItem.text }}
              </button>
            </div>
          </div>
        </div>

        <button @click="logout" class="w-full mt-10 bg-[#994D1C] rounded-full py-1.5 text-white">
          ออกจากระบบ
        </button>
      </div>
    </div>
  </div>

  <nav 
    v-if="isMobile" 
    aria-label="toggle sidebar" 
    class="z-20 flex-col sticky top-0 items-center w-16 py-4bg-white flex"
  >
    <div class="flex flex-col items-center flex-1 p-2 space-y-4">
      <!-- Menu button -->
      <button v-if="isMobile" @click="toggleMenu"
        class="p-2 transition-colors rounded-lg shadow-md focus:outline-none focus:ring focus:ring-[#864000] focus:ring-offset-white focus:ring-offset-2"
        :class="{
          'text-white bg-[#864000]': isSidebarOpen,
          'text-[#994D1C] bg-white': !isSidebarOpen,
        }">
        <span class="sr-only">Toggle sidebar</span>
        <svg width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill="currentColor"
            d="M2 2h20v20H2zm2 2v5.5h16V4zm16 7.5H4V20h16zM5.996 6H8v2h-.004v.004h-2zM10 6h8v2h-8z" />
        </svg>
      </button>
    </div>
  </nav>

  <!-- mobile -->
  <transition name="fade">
    <div 
      v-if="isMobile" 
      :class="{ hidden: !isSidebarOpen }" 
      style="height: auto;" 
      class="w-1/2 pb-5 pt-10 overflow-auto fixed inset-y-0 z-10 bg-[#F5CCA0] border-[#E48F45] flex-shrink-0 border-r-2 shadow-lg left-0 flex-col rounded-br-3xl rounded-tr-3xl"
    >
      <div class="px-2">
        <div v-for="menuItem in menuItems" :key="menuItem.text">
          <button @click="handleMenuItemClick(menuItem)"
            class="flex items-center w-full space-x-2 mt-2 group hover:bg-[#994D1C] hover:text-[#F5CCA0] rounded-lg z-50">
            <span aria-hidden="true" class="p-2 z-10 group-hover:bg-[#6B240C] group-hover:text-[#F5CCA0] rounded-lg">
              <!-- svg -->
              <Icon :iconName="menuItem.icon" class="pt-1" />
            </span>
            <span class="font-medium">{{ menuItem.text }}</span>
            <svg v-if="menuItem.submenu" width="20" height="20" fill="currentColor" viewBox="0 0 20 20" :class="{
              'rotate-180': menuItem.showSubmenu,
              'rotate-0': !menuItem.showSubmenu,
            }" class="inline mt-1 ml-1 transition-transform duration-200 transform md:-mt-1">
              <path fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
          </button>

          <!-- Additional submenu for specific items -->
          <div v-if="menuItem.submenu && menuItem.showSubmenu" class="mt-2 origin-top-right rounded-md shadow-lg">
            <div class="px-2 py-2 bg-white rounded-md shadow">
              <button v-for="subItem in menuItem.submenu" :key="subItem.text" @click="handleSubmenuItemCick(subItem)"
                class="flex items-center w-full px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
                {{ subItem.text }}
              </button>
            </div>
          </div>
        </div>
        <button @click="logout" class="w-full mt-10 bg-[#994D1C] rounded-full py-1.5 text-white">
          ออกจากระบบ
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Icon from "./Icon.vue";

export default {
  components: {
    Icon,
  },

  data() {
    return {
      isSidebarOpen: false,
      isMobile: false,
      isSubmenuOpen: false,

      menuItems: [
        { text: "หน้าแรก", action: "/", icon: "HomeIcon", role: "แอดมิน" },
        { text: "ออร์เดอร์", action: "/orders", icon: "HomeIcon", role: "แอดมิน" },
        {
          text: "Sale",
          submenu: [
            { text: "สร้างฟร์อมเสนอราคา", path: "/addform" },
            { text: "ฟอร์มที่สร้างแล้ว", path: "/sale/preOrder" },
            { text: "รายการรอคำนวณ", path: "/pre-productions" },
            { text: "ใบเสนอราคา", path: "/Quotation", icon: "Calculate" },
          ],
          icon: "Form",
        },
        {
          text: "Graphic",
          submenu: [
            { text: "รายการค้างเลเอาท์", path: "/Pro/ProOrder" },
            { text: "รายการลงเลเอาท์ทั้งหมด", path: "/Pro/productions" }
          ],
          icon: "ListPreOrder",
        },
        {
          text: "Planing",
          submenu: [
            //{ text: "ออร์เดอร์", path: "/planing/orders" },
            { text: "ตารางงาน", path: "/planing/WorkSchedule" },
          ],
          icon: "Form",
        },
        {
          text: "Purchase",
          submenu: [
            { text: "งานทั้งหมด", path: "/purchase/all" },
            { text: "งานใหม่", path: "/purchase/new" },
            { text: "งานที่กำลังดำเนินการ", path: "/purchase/pending" },
            { text: "งานที่เสร็จแล้ว", path: "/purchase/done" },
          ],
          icon: "Form",
        },
        {
          text: "Production",
          submenu: [
            { text: "งานทั้งหมด", path: "/production/all" },
            { text: "งานใหม่", path: "/production/new" },
            { text: "งานที่กำลังดำเนินการ", path: "/production/pending" },
            { text: "งานที่เสร็จแล้ว", path: "/production/done" },
          ],
          icon: "Form",
        },
        { text: "จัดส่ง", action: "/transfer", icon: "HomeIcon", role: "แอดมิน" },
        
        {
          text: "วัตถุดิบ",
          submenu: [
            { text: "RawMatt", path: "/admin/rawmatt" },
            { text: "Plate", path: "/admin/plate" },
            { text: "Print IV", path: "/admin/print4" },
            { text: "Print II", path: "/admin/print2" },
            { text: "Hot Stamp", path: "/admin/hotStamp" },
            { text: "Emboss", path: "/admin/emboss" },
            { text: "Coating", path: "/admin/coating" },
            { text: "Diecut", path: "/admin/diecut" },
            { text: "Glue", path: "/admin/glue" },
          ],
          icon: "Calculation",
        },
        { text: "ลูกค้า", action: "/Customer", icon: "pi pi-users", role: "แอดมิน" },
        {
          text: "จัดการผู้ใช้",
          submenu: [
            { text: "เพิ่มผู้ใช้งาน", path: "/add/admin" },
            { text: "พนักงานทั้งหมด", path: "/admin/all" },
            { text: "ลูกค้าทั้งหมด", path: "/Customer" },
          ],
          icon: "AddUser",
        },
      ],
    };
  },

  mounted() {
    setInterval(() => {
      this.menuItems.forEach((menuItem) => {
        this.fetchItemCount(menuItem);
      });
    }, 5000);

    // ตรวจสอบหน้าจอ mobile หรือไม่
    this.isMobile = window.innerWidth <= 912;

    // เพิ่ม event listener เพื่อตรวจสอบการปรับขนาดหน้าจอ
    window.addEventListener("resize", this.handleResize);
  },
  async created() { },
  methods: {
    shouldShowItemCount(menuItem) {
      return (
        menuItem.action !== "/Profile" &&
        menuItem.action !== "/add/form" &&
        menuItem.action !== "/home" &&
        menuItem.action !== "/add/admin" &&
        menuItem.action !== "/Pro" &&
        menuItem.action !== "/Customer" &&
        !menuItem.submenu
      );
    },
    async fetchItemCount(menuItem) {
      try {
        let apiUrl = "";

        // ตรวจสอบเงื่อนไขและกำหนด URL ตามเมนู
        if (menuItem.action === "/sale/preOrder") {
          
          apiUrl = `${process.env.VUE_APP_DIAMOND}/order/pre-orders`;
        } else if (menuItem.action === "/Pro/ProOrder") {
          
          apiUrl = `${process.env.VUE_APP_DIAMOND}/order/pre-orders`;
        } else if (menuItem.action === "/Pro/productions") {
          
          apiUrl = `${process.env.VUE_APP_DIAMOND}/order/pre-productions`;
        } else if (menuItem.action === "/pre-productions") {
          
          apiUrl = `${process.env.VUE_APP_DIAMOND}/order/pre-productions`;
        } else if (menuItem.action === "/Quotation") {
          
          apiUrl = `${process.env.VUE_APP_DIAMOND}/order/quotations`;
        }

        if (apiUrl) {
          const response = await axios.get(apiUrl);

          // ใน Vue 3 คุณไม่ต้องใช้ Vue.set หรือ this.$set
          if (menuItem.action === "/sale/preOrder") {
            menuItem.itemCount = response.data.preOrders.filter(item => item.status[item.status.length - 1].name === 'new').length;
          } else if (menuItem.action === "/Pro/ProOrder") {
            menuItem.itemCount = response.data.preOrders.filter(item => item.status[item.status.length - 1].name === 'new').length;
          } else if (menuItem.action === "/pre-productions") {
            menuItem.itemCount = response.data.preProductions.filter(item => item.status[item.status.length - 1].name === 'new').length;
          } else if (menuItem.action === "/Pro/productions") {
            menuItem.itemCount = response.data.preProductions.filter(item => item.status[item.status.length - 1].name === 'new').length;
          } else if (menuItem.action === "/Quotation") {
            menuItem.itemCount = response.data.quotations.length;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async logout() {
      const result = await Swal.fire({
        text: "คุณต้องการที่จะออกจากระบบหรือไม่?",
        icon: "warning",
        iconColor: "#864000",
        showCancelButton: true,
        confirmButtonColor: "#864000",
        cancelButtonColor: "#9BABB8",
        confirmButtonText: "ยืนยันออกจากระบบ",
        cancelButtonText: "ยกเลิก",
      });

      if (result.isConfirmed) {
        // ทำการลบ Token ออกจาก localStorage
        localStorage.clear();
        this.$store.commit("setLoginDefault");

        // ทำการล็อกเอาท์ใน Store
        this.$router.push("/");
      }
    },
    handleMenuItemClick(menuItem) {
      if (menuItem.action) {
        this.$router.push(menuItem.action);
      } else if (menuItem.submenu) {
        this.menuItems.forEach((item) => {
          if (item !== menuItem) {
            item.showSubmenu = false;
          }
        });
        menuItem.showSubmenu = !menuItem.showSubmenu;
      }
    },

    handleSubmenuItemCick(subItem) {
      if (subItem.path) {
        this.$router.push(subItem.path);
      }
    },

    handleResize() {
      this.isMobile = window.innerWidth <= 912;
    },
    toggleMenu() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    toggleSubmenu(menuItem) {
      menuItem.showSubmenu = !menuItem.showSubmenu;
    },
    closeMenu() {
      this.isSidebarOpen = false;
    },
  },
};
</script>

<style scoped>
/* styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
