<!-- IconMenu.vue -->
<template>
    <div>
      <span
        aria-hidden="true"
        class="p-2 group-hover:bg-[#6B240C] items-center group-hover:text-[#F5CCA0] rounded-lg"
        @mouseover="handleHover"
        @mouseleave="handleHover"
      >
        <component :is="currentIcon" :isHover="isHover" />
      </span>
    </div>
  </template>
  
  <script>
  import HomeIcon from "@/components/icon/HomeIcon.vue";
  import ProfileIcon from "@/components/icon/ProfileIcon.vue";
  import AddProductIcon from "@/components/icon/AddProductIcon.vue";
  import AddUser from "@/components/icon/AddUser.vue";
  import Product from "@/components/icon/Product.vue";
  import Form from "@/components/icon/Form.vue";
  import Calculation from "@/components/icon/Calculation.vue";
  import ListPreOrder from "@/components/icon/List_PreOrder.vue";
  import Calculate from "@/components/icon/Calculate.vue";
  import Customer from "@/components/icon/Customer.vue";

  export default {
    components: {
      HomeIcon,
      ProfileIcon,
      AddProductIcon,
      AddUser,
      Product,
      Form,
      Calculation,
      ListPreOrder,
      Calculate,
      Customer
    },
    data() {
      return {
        isHover: false,
      };
    },
    props: {
      iconName: String,
    },
    computed: {
      currentIcon() {
        if (this.iconName === "home-icon") {
          return "HomeIcon";
        } else if (this.iconName === "profile") {
          return "ProfileIcon";
        } else if (this.iconName === "add-product") {
          return "AddProductIcon";
        } else if (this.iconName === "AddUser") {
          return "AddUser";
        } else if (this.iconName === "Product") {
          return "Product";
        } else if (this.iconName === "Form") {
          return "Form";
        } else if (this.iconName === "Calculation") {
          return "Calculation";
        } else if (this.iconName === "ListPreOrder") {
          return "ListPreOrder";
        } else if (this.iconName === "Calculate") {
          return "Calculate";
        } else if (this.iconName === "Customer") {
          return "Customer";
        }
        
        return "HomeIcon";
      },
    },
    methods: {
      handleHover() {
        this.isHover = !this.isHover;
      },
    },
  };
  </script>
  