<!-- HomeIcon.vue -->
<template>

    <svg  :class="{ 'icon-image': isHover }"
      fill="currentColor"
      width="22"
      height="22" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M26 30h-2v-5a5.006 5.006 0 0 0-5-5h-6a5.006 5.006 0 0 0-5 5v5H6v-5a7.008 7.008 0 0 1 7-7h6a7.008 7.008 0 0 1 7 7zM22 6v4c0 1.103-.897 2-2 2h-1a1 1 0 0 0 0 2h1c2.206 0 4-1.794 4-4V6zm-6 10c-3.86 0-7-3.14-7-7s3.14-7 7-7c1.989 0 3.89.85 5.217 2.333l-1.49 1.334A5.008 5.008 0 0 0 16 4c-2.757 0-5 2.243-5 5s2.243 5 5 5z"/>
</svg>
  </template>
  
  <script>
  export default {
    props: {
      isHover: Boolean,
    },
  };
  </script>
  