import { createRouter, createWebHistory } from "vue-router";

let routes = [];
routes = [
 

  {
    path: "/",
    name: "Production",
    component: () => import("../components/Production/ProductionView.vue"),
  },
  {
    path: "/new",
    name: "Production-new",
    component: () => import("../components/Production/ProductionNewView.vue"),
  },
  {
    path: "/pending",
    name: "Production-pending",
    component: () => import("../components/Production/ProductionPendingView.vue"),
  },
  {
    path: "/done",
    name: "Production-done",
    component: () => import("../components/Production/ProductionDoneView.vue"),
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
